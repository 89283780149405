import * as styles from "./blog.module.scss";

import Layout from "../components/Layout";
import React from "react";
import SeoHelmet from "../components/SeoHelmet";
import { graphql } from "gatsby";

export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        author
        date(formatString: "D MMMM YYYY")
        title
        draft
      }
      html
    }
  }
`;

export default function Blog({ data }) {
  return (
    <Layout headerProps={{ altNav: true }}>
      <div className={styles.pageContent}>
        <SeoHelmet title={data.markdownRemark.frontmatter.title} />
        <h1>{data.markdownRemark.frontmatter.title}</h1>
        <p className={styles.intro}>{data.markdownRemark.frontmatter.date}</p>
        <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
      </div>
    </Layout>
  );
}
